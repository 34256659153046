.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.filter-orange {
  filter: invert(33%) sepia(87%) saturate(1768%) hue-rotate(3deg) brightness(109%) contrast(102%);
}

.filter-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(101%) contrast(102%);
}

.filter-yellow {
  filter: invert(62%) sepia(76%) saturate(731%) hue-rotate(6deg) brightness(112%) contrast(105%);
}

.filter-pink {
  filter: invert(39%) sepia(100%) saturate(6703%) hue-rotate(313deg) brightness(100%) contrast(122%);
}

.animate-shake {
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  20% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}
