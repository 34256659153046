.btn {
  box-shadow: none;
  padding: 0.5rem 1rem;
  &-danger {
    background-color: $red;
    color: $white !important;
  }
}

// General

@keyframes MoveRightInitial {
  to {
    transform: translate3d(105%, 0, 0);
  }
}

@keyframes MoveRightEnd {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.underline-theme {
  .btn {
    overflow: hidden;
    font-size: 0.8rem;
    border-radius: 10px;
    border-bottom: 4px solid $pinterest;
    transition: all 0.4s ease;
    padding: 6px 9px;
    text-transform: uppercase;
    &:hover,
    &:focus {
      transform: translate(0px, 3px);
    }
  }
}

.rounded-theme {
  .btn {
    position: relative;
    transition: $transition-base;
    border-radius: 20px;
    will-change: transform;
    letter-spacing: 1.1;
    font-size: $font-size-base;
    display: inline-block;
    white-space: break-spaces;

    &:hover {
      box-shadow: $btn-hover-box-shadow;
      transform: translateY(-1px);
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .btn-group,
  .input-group {
    .btn {
      margin-right: 0;
      transform: translateY(0);
    }
  }

  // Sizes

  .btn-sm {
    font-size: $input-btn-font-size-sm;
  }

  .btn-lg {
    font-size: $input-btn-font-size-lg;
  }

  // Fixes

  [class*="btn-outline-"] {
    border-width: 1px;
  }

  .btn-inner--icon {
    i:not(.fa) {
      position: relative;
      top: 2px;
    }
  }

  .btn-link {
    font-weight: $btn-font-weight;
    box-shadow: none;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  .btn-neutral {
    color: theme-color("primary");
  }

  // Icons

  .btn svg:not(:first-child),
  .btn i:not(:first-child) {
    margin-left: 0.5rem;
  }
  .btn svg:not(:last-child),
  .btn i:not(:last-child) {
    margin-right: 0.5rem;
  }

  // Icon labels

  .btn-icon-label {
    position: relative;

    .btn-inner--icon {
      position: absolute;
      height: 100%;
      line-height: 1;
      border-radius: 0;
      text-align: center;
      margin: 0;
      width: 3em;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .btn-inner--icon:not(:first-child) {
      right: 0;
      top: 0;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    .btn-inner--icon:not(:last-child) {
      left: 0;
      top: 0;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
    .btn-inner--icon svg {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .btn-inner--text:not(:first-child) {
      padding-left: 3em;
    }
    .btn-inner--text:not(:last-child) {
      padding-right: 3em;
    }
  }

  // Icons

  .btn-icon {
    .btn-inner--icon {
      img {
        width: 20px;
      }
    }
    .btn-inner--text:not(:first-child) {
      margin-left: 0.75em;
    }
    .btn-inner--text:not(:last-child) {
      margin-right: 0.75em;
    }
  }

  .btn-icon-only {
    width: 2.375rem;
    height: 2.375rem;
    padding: 0;
  }
  a.btn-icon-only {
    line-height: 2.5;
  }
  .btn-icon-only.btn-sm {
    width: 2rem;
    height: 2rem;
  }

  // Brand buttons

  // @each $color, $value in $brand-colors {
  //   .btn-#{$color} {
  //     @include button-variant($value, $value);
  //   }
  // }
}
