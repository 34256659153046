.mat-drawer-container {
  background-color: #fff !important;
}

.flip-image {
  transform: scaleX(-1);
}
.hide-list-icon {
  margin: 8px 0;
  padding: 0;
  li {
    list-style-type: circle;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    img {
      margin-right: 0.5rem;
    }
  }
}
.list {
  margin: 8px 16px;
  padding: 0;
  li {
    list-style-type: circle;
    color: #000;
    margin-bottom: 1rem;
    img {
      margin-right: 0.5rem;
    }
  }
}

.video-background {
  &::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.85);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  video {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.video {
  box-shadow: 3px 5px 10px #000;
}

.mark {
  &:before {
    transition: all 0.7s;
    content: "";
    position: absolute;

    top: 77%;
    width: 100%;
    height: 28%;
    left: -1px;
    background: transparent linear-gradient(268deg, $neon 0, $red 100%) 0 0 no-repeat padding-box;
    opacity: 0.4;
  }
}

.line {
  padding-left: 108px;
  &::before {
    content: "";
    width: 100px;
    height: 1px;
    background-color: #18181b;
    align-self: center;
    left: 0;
    top: 50%;
    position: absolute;
  }
}

.gelatine-cell {
  position: absolute;
  z-index: 1;
  display: inline-block;
  width: 49%;
  top: 30px;
  text-align: center;
}
.circle {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: whiteSmoke;
  box-shadow: 4px -40px 60px 5px rgb(26, 117, 206) inset;
}

.gelatine {
  animation: gelatine 0.5s infinite;
}
@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
