.mat-dialog-container {
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
        0px 9px 46px 8px rgb(0 0 0 / 12%);
    background: rgb(247, 247, 247);
    color: rgba(12, 12, 12, 0.87);
}
.modal-body {
    width: 100%;
    max-width: 800px;
    @media screen and (min-width: 580px) {
        width: 100%;
        min-width: 500px;
    }
    @media screen and (min-width: 780px) {
        width: 100%;
        min-width: 650px;
    }
    @media screen and (min-width: 960px) {
        width: 100%;
        min-width: 800px;
    }
    height: 432px;
}

.close.mat-icon-button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    line-height: 14px;
    min-width: auto;
}
