.font-prompt {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-prompt;
  }
  p {
    font-family: $font-family-base;
  }
}
.font-montserrat {
  font-family: $font-family-montserrat;
}
.font-raleway {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-raleway;
  }
  p {
    font-family: $font-family-alt;
  }
}

p {
  font-size: $paragraph-font-size;
  font-weight: 400;
  line-height: $paragraph-line-height;
  margin-bottom: 1rem !important;

  a {
    color: $neon;
    text-decoration: underline;
  }
}
#our-work,
#footer {
  ul {
    margin-left: 0;
    margin-bottom: 16px;
    list-style-type: none;
  }
}

ul {
  margin-left: 24px;
  margin-bottom: 16px;
  list-style-type: disc;
}
// List
.list-article {
  li {
    &:before {
      content: "\f006";
      font-family: FontAwesome;
      color: $neon;
      margin-right: 16px;
    }
  }
}

.description {
  font-size: $font-size-sm;
}

// Headings
h1,
h2,
h3,
h4 {
  font-family: $font-family-alt;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: inherit;
  font-weight: 400;
}

h1,
.h1 {
  font-size: 3rem;
  @media screen and (min-width: 320px) {
    font-size: calc(2rem + 6 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    font-size: 2.75rem;
  }
}
h2,
.h2 {
  font-size: 2.75rem;
  @media screen and (min-width: 320px) {
    font-size: 2.5rem;
  }
}
h3,
.h3 {
  font-size: 2.5rem;
  @media screen and (min-width: 320px) {
    font-size: calc(1.5rem + 6 * ((100vw - 320px) / 680));
  }
}
h4,
.h4 {
  font-size: 2.3125rem;
  @media screen and (min-width: 320px) {
    font-size: calc(1.5rem + 6 * ((100vw - 320px) / 680));
  }
}

// Articles

article {
  h4:not(:first-child),
  h5:not(:first-child) {
    margin-top: 3rem;
  }

  h4,
  h5 {
    margin-bottom: 1.5rem;
  }

  figure {
    margin: 3rem 0;
  }

  h5 + figure {
    margin-top: 0;
  }
}

// Text shadow
.text-shadow {
  text-shadow: 0px 3px 6px #000;
}

.line-height-08 {
  line-height: 0.8;
}

.bold-900 {
  font-family: $font-family-montserrat;
  font-weight: 900;
}
