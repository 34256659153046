input,
textarea {
  color: #111;
}

.form-ghost {
  input,
  select,
  textarea {
    color: #111;
    background-color: transparent;
    border-bottom: 2px solid #fff;
    border-radius: 0;
    color: #fff;
    font-size: 20px;
    &:focus {
      outline: none;
    }
  }
  select {
    option {
      color: #000;
    }
  }
}

.form-outline {
  .mat-error {
    position: absolute;
    bottom: 0;
  }
  display: flex;
  align-items: start;
  flex-direction: column;
  @media screen and (min-width: 780px) {
    flex-direction: row;
  }
  input {
    text-align: left;
    &::placeholder {
      font-weight: 500;
      text-transform: uppercase;
      opacity: 0.7;
      color: rgba(255, 255, 255, 0.767);
    }
  }

  .mat-form-field-flex {
    color: #222 !important;
    background-color: transparent !important;
    padding: 1px 10px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(255, 255, 255, 0.7);
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 4px;
  }
  .mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
    height: 4px;
  }

  .mat-flat-button.mat-primary.mat-button-disabled,
  .mat-flat-button.mat-accent.mat-button-disabled,
  .mat-flat-button.mat-warn.mat-button-disabled,
  .mat-flat-button.mat-button-disabled.mat-button-disabled,
  .mat-raised-button.mat-primary.mat-button-disabled,
  .mat-raised-button.mat-accent.mat-button-disabled,
  .mat-raised-button.mat-warn.mat-button-disabled,
  .mat-raised-button.mat-button-disabled.mat-button-disabled,
  .mat-fab.mat-primary.mat-button-disabled,
  .mat-fab.mat-accent.mat-button-disabled,
  .mat-fab.mat-warn.mat-button-disabled,
  .mat-fab.mat-button-disabled.mat-button-disabled,
  .mat-mini-fab.mat-primary.mat-button-disabled,
  .mat-mini-fab.mat-accent.mat-button-disabled,
  .mat-mini-fab.mat-warn.mat-button-disabled,
  .mat-mini-fab.mat-button-disabled.mat-button-disabled {
    background-color: rgba(0, 0, 0, 0.507);
  }
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1.35rem;
  }
}

.bottom-20-minus {
  bottom: -20px;
}
