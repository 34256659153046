.section {
  position: relative;
  padding-top: 4rem;
}

.section-xl {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.section-lg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

// Hero sections
// .section-hero {
//   @include media-breakpoint-up(md) {
//     min-height: 500px;
//   }
// }

// Circle sections
.section-shaped {
  position: relative;

  .stars-and-coded {
    margin-top: 8rem;
  }
  .shape {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
    }

    + .container {
      position: relative;
      height: 100%;
    }

    &.shape-skew {
      + .container {
        padding-top: 0;
        .col {
          margin-top: -100px;
        }
      }

      + .shape-container {
        padding-top: 18rem;
        padding-bottom: 19rem;
      }
    }
  }

  .shape-skew {
    transform: skewY(-4deg);
    transform-origin: 0;

    span {
      transform: skew(4deg);
    }
  }

  %bubbleSize {
    span {
      border-radius: 50%;
      box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2);
    }

    .span-200 {
      height: 200px;
      width: 200px;
    }

    .span-150 {
      height: 150px;
      width: 150px;
    }

    .span-100 {
      height: 100px;
      width: 100px;
    }

    .span-75 {
      height: 75px;
      width: 75px;
    }

    .span-50 {
      height: 50px;
      width: 50px;
    }
    :nth-child(1) {
      top: 20%;
      left: 4%;
      width: 54px;
      height: 54px;
      bottom: auto;
    }
    :nth-child(2) {
      right: 12%;
      top: 10%;
      width: 23px;
      height: 23px;
    }

    :nth-child(3) {
      top: 80%;
      right: 17%;
      width: 12px;
      height: 12px;
    }

    :nth-child(4) {
      top: 30%;
      right: 17%;
      height: 200px;
      width: 200px;
    }

    :nth-child(5) {
      top: 56%;
      right: 7%;
      height: 100px;
      width: 100px;
    }
  }

  %colorLight {
    background: $gray-200;
    background: linear-gradient(30deg, $white 23%, $gray-400 45%);
    color: #000;
  }

  %colorBlue {
    background: $blue;
    background: linear-gradient(210deg, $blue 23%, $purple 113%, $purple 94%);
    color: $white;
  }

  %colorDefault {
    background: $teal;
    background: linear-gradient(30deg, $teal 23%, $gray-300 45%);
    color: #000;
  }

  %colorHalloween {
    background: #000;
    background: linear-gradient(170deg, $orange 23%, $purple 73%, #000 114%);
    color: $white;
  }
  %colorHalloweenSkin {
    background: $purple;
    background: linear-gradient(210deg, $orange 33%, $purple 73%, $neon 94%);
  }

  %colorLove {
    background: $red;
    background: linear-gradient(0deg, $red 0%, $red 100%);
    color: $white;
  }
  %colorLoveSkin {
    background: $red;
    background: linear-gradient(45deg, $red 20%, $red 70%, $red 114%);
  }
  %colorBlack {
    background: #000;
    background: linear-gradient(210deg, $gray-800 23%, #000 113%, $gray-400 94%);
    color: $white;
  }

  .shape-default {
    @extend %colorDefault;
  }

  .shape-blue {
    @extend %colorBlue;
    @extend %bubbleSize;
    &--bubble {
      :nth-child(1),
      :nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(5) {
        @extend %colorBlue;
      }
    }
  }
  .shape-light {
    @extend %colorLight;
    @extend %bubbleSize;
    &--bubble {
      :nth-child(1),
      :nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(5) {
        @extend %colorLight;
      }
    }
  }

  .shape-halloween {
    @extend %colorHalloween;
    @extend %bubbleSize;
    &--bubble {
      :nth-child(1),
      :nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(5) {
        @extend %colorHalloweenSkin;
      }
    }
  }
  .shape-love {
    @extend %colorLove;
    @extend %bubbleSize;
    &--bubble {
      :nth-child(1),
      :nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(5) {
        @extend %colorLoveSkin;
      }
    }
  }
  .shape-black {
    @extend %colorBlack;
    @extend %bubbleSize;
    &--bubble {
      :nth-child(1),
      :nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(5) {
        @extend %colorBlack;
      }
    }
  }
}

%backgroundImage {
  height: 100%;
  position: relative;
  &::before {
    content: "";

    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  @media screen and (max-width: 780px) {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}

%overlayBlackBackgroundImage {
  &::before {
    background-color: rgba(0, 0, 0, 0.7);
  }
  @media screen and (max-width: 780px) {
    &::before {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}

%overlayWhiteBackgroundImage {
  &::before {
    background-color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: 780px) {
    &::before {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}

%overlayBlendHardLight {
  mix-blend-mode: hard-light;
}

.bg-overlay {
  @extend %backgroundImage;
}

.overlay-black {
  @extend %overlayBlackBackgroundImage;
}
.overlay-white {
  @extend %overlayWhiteBackgroundImage;
}

// Device illustrations used on shape sections
.device-ill {
  pointer-events: none;
  position: absolute;
  display: flex;
  width: 1287px;
  left: 50%;
  margin-left: -644px;
  transform: scale(0.5) rotate(-12deg) translateX(50px);
  transform-origin: 50% 20%;
  will-change: transform;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    padding: 0.875rem;
    background: $white;
    box-shadow: inset 0 4px 7px 1px $white, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14),
      0 10px 20px rgba(0, 21, 64, 0.05);
  }

  .tablet-landscape {
    width: 512px;
    height: 352px;
    margin: 115px 50px 0;
  }
}

@media (min-width: 670px) {
  .device-ill {
    flex-wrap: wrap;
    width: 512px;
    margin-left: -50px;
    top: 215px;
    transform: rotate(-12deg);
    transform-origin: 100% 0;

    [class^="tablet"] {
      margin: 0;
    }

    .tablet-landscape {
      width: 512px;
      height: 352px;
    }
  }
}

@media (min-width: 880px) {
  .device-ill {
    width: 829px;
    margin-left: -10px;
    top: 20px;

    .tablet-landscape {
      align-self: flex-end;
      margin-right: 50px;
    }

    .phone-big {
      display: flex;
      width: 267px;
      height: 553px;
    }
  }
}

// Profile cover
.section-profile-cover {
  height: 580px;
  background-size: cover;
  background-position: center center;
}

// @include media-breakpoint-down(md) {
//   .section-profile-cover {
//     height: 400px;
//   }
// }

// Components section (for demo)
.section-components {
  > .form-control {
    + .form-control {
      margin-top: 0.5rem;
    }
  }

  > .nav + .nav,
  > .alert + .alert,
  > .navbar + .navbar,
  > .progress + .progress,
  > .progress + .btn,
  .badge,
  .btn {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .btn-group {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .btn {
      margin: 0;
    }
  }

  .alert {
    margin: 0;

    + .alert {
      margin-top: 1.25rem;
    }
  }

  .badge {
    margin-right: 0.5rem;
  }

  .modal-footer {
    .btn {
      margin: 0;
    }
  }
}

.floating-cards {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.floating-cards > div {
  position: absolute;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}
.floating-cards .shine {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
