.footer {
  ul li {
    line-height: 1.6;
    font-size: 14px;
    a {
      &:hover {
        color: #5472d2;
        text-decoration: underline;
      }
    }
  }
}
