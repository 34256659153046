.social-network-content {
  display: flex;
  .social-network {
    list-style-type: none;
    padding: 8px;
    a {
      color: $neon;
      &:hover {
        color: $gray-600;
      }
    }
    .social-icon-circle {
      color: $teal;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: $red;
      transition: all 0.4s ease-out;
      &:hover {
        height: 32px;
        width: 32px;
        background-color: $teal;
        color: $red;
      }
    }
  }
}
