.mat-sidenav-container {
  height: 100%;
  font-family: $font-family-alt;
}
.navbar a {
  position: relative;
}
.mat-toolbar {
  background: $white;
  color: $dark;
  z-index: 20;
  position: relative;
}
.mat-menu-panel {
  background: #ffffff;
}
.mat-menu-content {
  .mat-menu-item .mat-icon-no-color,
  .mat-menu-submenu-icon {
    color: rgba(0, 0, 0, 0.5);
  }
  .mat-menu-item[disabled],
  .mat-menu-item[disabled] .mat-menu-submenu-icon,
  .mat-menu-item[disabled] .mat-icon-no-color {
    color: rgba(0, 0, 0, 0.5);
  }
  .mat-menu-item .mat-icon {
    margin-right: 16px;
    vertical-align: middle;
    text-align: center;
    font-size: 21px;
  }
  .mat-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    color: #010101;
  }
}

.mat-sidenav-container .mat-sidenav {
  background-color: $white;
  padding: 16px;
  width: 90%;
  min-width: 150px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

.mat-sidenav-container .mat-sidenav label {
  color: $dark;
}

.mat-sidenav-container .mat-sidenav nav {
  margin-top: 15px;
}

.mat-sidenav-container .mat-sidenav nav a {
  display: block;
  outline: 0px;
  margin: 16px 0px;
  text-decoration: none;
  color: #272727;
  font-size: 28px;
  text-align: center;
  border-radius: 0;
}
.mat-sidenav-container .mat-sidenav nav a:hover {
  color: #203b94;
}
.mat-sidenav-container .mat-sidenav nav a.active {
  color: #212121;
  border-bottom: 3px solid #8ba0e7;
  padding: 5px 10px;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar {
  height: 80px;
  padding: 0px;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header .mat-icon {
  display: none;
  cursor: pointer;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header nav a {
  margin-left: 16px;
  text-decoration: none;
  font-size: 1rem;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header nav a.active {
  &:before {
    transition: all 0.7s;
    content: "";
    position: absolute;
    top: 77%;
    width: 80%;
    height: 3px;
    left: 50%;
    transform: translate(-50%, 50%);
    background: $blue;
    opacity: 0.4;
  }
  color: $blue;
}

@media screen and (max-width: 1023px) {
  .mat-sidenav-container .mat-sidenav-content .mat-toolbar {
    padding-right: 10px;
    padding-left: 5px;
  }
  .mat-sidenav-container .mat-sidenav-content .mat-toolbar header .mat-icon {
    display: block;
    cursor: pointer;
  }
  .mat-sidenav-container .mat-sidenav-content .mat-toolbar header nav {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .mat-sidenav-container .mat-sidenav-content .mat-toolbar header {
    max-width: 1024px;
    margin: 0 auto;
  }
  .mat-sidenav-container .mat-sidenav-content section {
    margin: 0 auto;
  }
}

.navbar-fixed {
  position: fixed;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  img {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(123deg) brightness(450%) contrast(100%);
  }
  a {
    color: #fff;
  }
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  background-color: #fff;
  box-shadow: 1px 2px 15px rgba(39, 39, 39, 0.3);
  img {
    filter: none;
  }
  a {
    color: #121212;
  }
}
