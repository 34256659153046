@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&family=Nanum+Gothic:wght@400;800&display=swap");
.btn {
  box-shadow: none;
  padding: 0.5rem 1rem;
}
.btn-danger {
  background-color: #ef2d56;
  color: #fff !important;
}

@keyframes MoveRightInitial {
  to {
    transform: translate3d(105%, 0, 0);
  }
}
@keyframes MoveRightEnd {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.underline-theme .btn {
  overflow: hidden;
  font-size: 0.8rem;
  border-radius: 10px;
  border-bottom: 4px solid #bd081c;
  transition: all 0.4s ease;
  padding: 6px 9px;
  text-transform: uppercase;
}
.underline-theme .btn:hover, .underline-theme .btn:focus {
  transform: translate(0px, 3px);
}

.rounded-theme .btn {
  position: relative;
  transition: all 0.5s ease;
  border-radius: 20px;
  will-change: transform;
  letter-spacing: 1.1;
  font-size: 1rem;
  display: inline-block;
  white-space: break-spaces;
}
.rounded-theme .btn:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}
.rounded-theme .btn:not(:last-child) {
  margin-right: 0.5rem;
}
.rounded-theme .btn-group .btn,
.rounded-theme .input-group .btn {
  margin-right: 0;
  transform: translateY(0);
}
.rounded-theme .btn-sm {
  font-size: 0.75rem;
}
.rounded-theme .btn-lg {
  font-size: 0.875rem;
}
.rounded-theme [class*=btn-outline-] {
  border-width: 1px;
}
.rounded-theme .btn-inner--icon i:not(.fa) {
  position: relative;
  top: 2px;
}
.rounded-theme .btn-link {
  font-weight: 600;
  box-shadow: none;
}
.rounded-theme .btn-link:hover {
  box-shadow: none;
  transform: none;
}
.rounded-theme .btn-neutral {
  color: theme-color("primary");
}
.rounded-theme .btn svg:not(:first-child),
.rounded-theme .btn i:not(:first-child) {
  margin-left: 0.5rem;
}
.rounded-theme .btn svg:not(:last-child),
.rounded-theme .btn i:not(:last-child) {
  margin-right: 0.5rem;
}
.rounded-theme .btn-icon-label {
  position: relative;
}
.rounded-theme .btn-icon-label .btn-inner--icon {
  position: absolute;
  height: 100%;
  line-height: 1;
  border-radius: 0;
  text-align: center;
  margin: 0;
  width: 3em;
  background-color: rgba(0, 0, 0, 0.1);
}
.rounded-theme .btn-icon-label .btn-inner--icon:not(:first-child) {
  right: 0;
  top: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.rounded-theme .btn-icon-label .btn-inner--icon:not(:last-child) {
  left: 0;
  top: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.rounded-theme .btn-icon-label .btn-inner--icon svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.rounded-theme .btn-icon-label .btn-inner--text:not(:first-child) {
  padding-left: 3em;
}
.rounded-theme .btn-icon-label .btn-inner--text:not(:last-child) {
  padding-right: 3em;
}
.rounded-theme .btn-icon .btn-inner--icon img {
  width: 20px;
}
.rounded-theme .btn-icon .btn-inner--text:not(:first-child) {
  margin-left: 0.75em;
}
.rounded-theme .btn-icon .btn-inner--text:not(:last-child) {
  margin-right: 0.75em;
}
.rounded-theme .btn-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
}
.rounded-theme a.btn-icon-only {
  line-height: 2.5;
}
.rounded-theme .btn-icon-only.btn-sm {
  width: 2rem;
  height: 2rem;
}

.mat-sidenav-container {
  height: 100%;
  font-family: "Jost", sans-serif;
}

.navbar a {
  position: relative;
}

.mat-toolbar {
  background: #fff;
  color: #171d1c;
  z-index: 20;
  position: relative;
}

.mat-menu-panel {
  background: #ffffff;
}

.mat-menu-content .mat-menu-item .mat-icon-no-color,
.mat-menu-content .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
}
.mat-menu-content .mat-menu-item[disabled],
.mat-menu-content .mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-content .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.5);
}
.mat-menu-content .mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
  text-align: center;
  font-size: 21px;
}
.mat-menu-content .mat-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  color: #010101;
}

.mat-sidenav-container .mat-sidenav {
  background-color: #fff;
  padding: 16px;
  width: 90%;
  min-width: 150px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

.mat-sidenav-container .mat-sidenav label {
  color: #171d1c;
}

.mat-sidenav-container .mat-sidenav nav {
  margin-top: 15px;
}

.mat-sidenav-container .mat-sidenav nav a {
  display: block;
  outline: 0px;
  margin: 16px 0px;
  text-decoration: none;
  color: #272727;
  font-size: 28px;
  text-align: center;
  border-radius: 0;
}

.mat-sidenav-container .mat-sidenav nav a:hover {
  color: #203b94;
}

.mat-sidenav-container .mat-sidenav nav a.active {
  color: #212121;
  border-bottom: 3px solid #8ba0e7;
  padding: 5px 10px;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar {
  height: 80px;
  padding: 0px;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header .mat-icon {
  display: none;
  cursor: pointer;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header nav a {
  margin-left: 16px;
  text-decoration: none;
  font-size: 1rem;
}

.mat-sidenav-container .mat-sidenav-content .mat-toolbar header nav a.active {
  color: #16bac5;
}
.mat-sidenav-container .mat-sidenav-content .mat-toolbar header nav a.active:before {
  transition: all 0.7s;
  content: "";
  position: absolute;
  top: 77%;
  width: 80%;
  height: 3px;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #16bac5;
  opacity: 0.4;
}

@media screen and (max-width: 1023px) {
  .mat-sidenav-container .mat-sidenav-content .mat-toolbar {
    padding-right: 10px;
    padding-left: 5px;
  }

  .mat-sidenav-container .mat-sidenav-content .mat-toolbar header .mat-icon {
    display: block;
    cursor: pointer;
  }

  .mat-sidenav-container .mat-sidenav-content .mat-toolbar header nav {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .mat-sidenav-container .mat-sidenav-content .mat-toolbar header {
    max-width: 1024px;
    margin: 0 auto;
  }

  .mat-sidenav-container .mat-sidenav-content section {
    margin: 0 auto;
  }
}
.navbar-fixed {
  position: fixed;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}
.navbar-fixed img {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(123deg) brightness(450%) contrast(100%);
}
.navbar-fixed a {
  color: #fff;
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  background-color: #fff;
  box-shadow: 1px 2px 15px rgba(39, 39, 39, 0.3);
}
.navbar-fixed-top img {
  filter: none;
}
.navbar-fixed-top a {
  color: #121212;
}

.social-network-content {
  display: flex;
}
.social-network-content .social-network {
  list-style-type: none;
  padding: 8px;
}
.social-network-content .social-network a {
  color: #5863f8;
}
.social-network-content .social-network a:hover {
  color: #666666;
}
.social-network-content .social-network .social-icon-circle {
  color: #daf1f5;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ef2d56;
  transition: all 0.4s ease-out;
}
.social-network-content .social-network .social-icon-circle:hover {
  height: 32px;
  width: 32px;
  background-color: #daf1f5;
  color: #ef2d56;
}

.mat-drawer-container {
  background-color: #fff !important;
}

.flip-image {
  transform: scaleX(-1);
}

.hide-list-icon {
  margin: 8px 0;
  padding: 0;
}
.hide-list-icon li {
  list-style-type: circle;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.hide-list-icon li img {
  margin-right: 0.5rem;
}

.list {
  margin: 8px 16px;
  padding: 0;
}
.list li {
  list-style-type: circle;
  color: #000;
  margin-bottom: 1rem;
}
.list li img {
  margin-right: 0.5rem;
}

.video-background::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.video-background video {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
}

.video {
  box-shadow: 3px 5px 10px #000;
}

.mark:before {
  transition: all 0.7s;
  content: "";
  position: absolute;
  top: 77%;
  width: 100%;
  height: 28%;
  left: -1px;
  background: transparent linear-gradient(268deg, #5863f8 0, #ef2d56 100%) 0 0 no-repeat padding-box;
  opacity: 0.4;
}

.line {
  padding-left: 108px;
}
.line::before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: #18181b;
  align-self: center;
  left: 0;
  top: 50%;
  position: absolute;
}

.gelatine-cell {
  position: absolute;
  z-index: 1;
  display: inline-block;
  width: 49%;
  top: 30px;
  text-align: center;
}

.circle {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: whiteSmoke;
  box-shadow: 4px -40px 60px 5px #1a75ce inset;
}

.gelatine {
  animation: gelatine 0.5s infinite;
}

@keyframes gelatine {
  from, to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
.footer ul li {
  line-height: 1.6;
  font-size: 14px;
}
.footer ul li a:hover {
  color: #5472d2;
  text-decoration: underline;
}

input,
textarea {
  color: #111;
}

.form-ghost input,
.form-ghost select,
.form-ghost textarea {
  color: #111;
  background-color: transparent;
  border-bottom: 2px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: 20px;
}
.form-ghost input:focus,
.form-ghost select:focus,
.form-ghost textarea:focus {
  outline: none;
}
.form-ghost select option {
  color: #000;
}

.form-outline {
  display: flex;
  align-items: start;
  flex-direction: column;
}
.form-outline .mat-error {
  position: absolute;
  bottom: 0;
}
@media screen and (min-width: 780px) {
  .form-outline {
    flex-direction: row;
  }
}
.form-outline input {
  text-align: left;
}
.form-outline input::placeholder {
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.7;
  color: rgba(255, 255, 255, 0.767);
}
.form-outline .mat-form-field-flex {
  color: #222 !important;
  background-color: transparent !important;
  padding: 1px 10px;
}
.form-outline .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.form-outline .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 4px;
}
.form-outline .mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
  height: 4px;
}
.form-outline .mat-flat-button.mat-primary.mat-button-disabled,
.form-outline .mat-flat-button.mat-accent.mat-button-disabled,
.form-outline .mat-flat-button.mat-warn.mat-button-disabled,
.form-outline .mat-flat-button.mat-button-disabled.mat-button-disabled,
.form-outline .mat-raised-button.mat-primary.mat-button-disabled,
.form-outline .mat-raised-button.mat-accent.mat-button-disabled,
.form-outline .mat-raised-button.mat-warn.mat-button-disabled,
.form-outline .mat-raised-button.mat-button-disabled.mat-button-disabled,
.form-outline .mat-fab.mat-primary.mat-button-disabled,
.form-outline .mat-fab.mat-accent.mat-button-disabled,
.form-outline .mat-fab.mat-warn.mat-button-disabled,
.form-outline .mat-fab.mat-button-disabled.mat-button-disabled,
.form-outline .mat-mini-fab.mat-primary.mat-button-disabled,
.form-outline .mat-mini-fab.mat-accent.mat-button-disabled,
.form-outline .mat-mini-fab.mat-warn.mat-button-disabled,
.form-outline .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.507);
}
.form-outline .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.35rem;
}

.bottom-20-minus {
  bottom: -20px;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #f7f7f7;
  color: rgba(12, 12, 12, 0.87);
}

.modal-body {
  width: 100%;
  max-width: 800px;
  height: 432px;
}
@media screen and (min-width: 580px) {
  .modal-body {
    width: 100%;
    min-width: 500px;
  }
}
@media screen and (min-width: 780px) {
  .modal-body {
    width: 100%;
    min-width: 650px;
  }
}
@media screen and (min-width: 960px) {
  .modal-body {
    width: 100%;
    min-width: 800px;
  }
}

.close.mat-icon-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}

.section {
  position: relative;
  padding-top: 4rem;
}

.section-xl {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.section-lg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section-shaped {
  position: relative;
}
.section-shaped .stars-and-coded {
  margin-top: 8rem;
}
.section-shaped .shape {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.section-shaped .shape span {
  position: absolute;
}
.section-shaped .shape + .container {
  position: relative;
  height: 100%;
}
.section-shaped .shape.shape-skew + .container {
  padding-top: 0;
}
.section-shaped .shape.shape-skew + .container .col {
  margin-top: -100px;
}
.section-shaped .shape.shape-skew + .shape-container {
  padding-top: 18rem;
  padding-bottom: 19rem;
}
.section-shaped .shape-skew {
  transform: skewY(-4deg);
  transform-origin: 0;
}
.section-shaped .shape-skew span {
  transform: skew(4deg);
}
.section-shaped .shape-black span, .section-shaped .shape-love span, .section-shaped .shape-halloween span, .section-shaped .shape-light span, .section-shaped .shape-blue span {
  border-radius: 50%;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2);
}
.section-shaped .shape-black .span-200, .section-shaped .shape-love .span-200, .section-shaped .shape-halloween .span-200, .section-shaped .shape-light .span-200, .section-shaped .shape-blue .span-200 {
  height: 200px;
  width: 200px;
}
.section-shaped .shape-black .span-150, .section-shaped .shape-love .span-150, .section-shaped .shape-halloween .span-150, .section-shaped .shape-light .span-150, .section-shaped .shape-blue .span-150 {
  height: 150px;
  width: 150px;
}
.section-shaped .shape-black .span-100, .section-shaped .shape-love .span-100, .section-shaped .shape-halloween .span-100, .section-shaped .shape-light .span-100, .section-shaped .shape-blue .span-100 {
  height: 100px;
  width: 100px;
}
.section-shaped .shape-black .span-75, .section-shaped .shape-love .span-75, .section-shaped .shape-halloween .span-75, .section-shaped .shape-light .span-75, .section-shaped .shape-blue .span-75 {
  height: 75px;
  width: 75px;
}
.section-shaped .shape-black .span-50, .section-shaped .shape-love .span-50, .section-shaped .shape-halloween .span-50, .section-shaped .shape-light .span-50, .section-shaped .shape-blue .span-50 {
  height: 50px;
  width: 50px;
}
.section-shaped .shape-black :nth-child(1), .section-shaped .shape-love :nth-child(1), .section-shaped .shape-halloween :nth-child(1), .section-shaped .shape-light :nth-child(1), .section-shaped .shape-blue :nth-child(1) {
  top: 20%;
  left: 4%;
  width: 54px;
  height: 54px;
  bottom: auto;
}
.section-shaped .shape-black :nth-child(2), .section-shaped .shape-love :nth-child(2), .section-shaped .shape-halloween :nth-child(2), .section-shaped .shape-light :nth-child(2), .section-shaped .shape-blue :nth-child(2) {
  right: 12%;
  top: 10%;
  width: 23px;
  height: 23px;
}
.section-shaped .shape-black :nth-child(3), .section-shaped .shape-love :nth-child(3), .section-shaped .shape-halloween :nth-child(3), .section-shaped .shape-light :nth-child(3), .section-shaped .shape-blue :nth-child(3) {
  top: 80%;
  right: 17%;
  width: 12px;
  height: 12px;
}
.section-shaped .shape-black :nth-child(4), .section-shaped .shape-love :nth-child(4), .section-shaped .shape-halloween :nth-child(4), .section-shaped .shape-light :nth-child(4), .section-shaped .shape-blue :nth-child(4) {
  top: 30%;
  right: 17%;
  height: 200px;
  width: 200px;
}
.section-shaped .shape-black :nth-child(5), .section-shaped .shape-love :nth-child(5), .section-shaped .shape-halloween :nth-child(5), .section-shaped .shape-light :nth-child(5), .section-shaped .shape-blue :nth-child(5) {
  top: 56%;
  right: 7%;
  height: 100px;
  width: 100px;
}
.section-shaped .shape-light--bubble :nth-child(1),
.section-shaped .shape-light--bubble :nth-child(2),
.section-shaped .shape-light--bubble :nth-child(3),
.section-shaped .shape-light--bubble :nth-child(4),
.section-shaped .shape-light--bubble :nth-child(5), .section-shaped .shape-light {
  background: #e9ecef;
  background: linear-gradient(30deg, #fff 23%, #ced4da 45%);
  color: #000;
}
.section-shaped .shape-blue--bubble :nth-child(1),
.section-shaped .shape-blue--bubble :nth-child(2),
.section-shaped .shape-blue--bubble :nth-child(3),
.section-shaped .shape-blue--bubble :nth-child(4),
.section-shaped .shape-blue--bubble :nth-child(5), .section-shaped .shape-blue {
  background: #16bac5;
  background: linear-gradient(210deg, #16bac5 23%, #712497 113%, #712497 94%);
  color: #fff;
}
.section-shaped .shape-default {
  background: #daf1f5;
  background: linear-gradient(30deg, #daf1f5 23%, #dee2e6 45%);
  color: #000;
}
.section-shaped .shape-halloween {
  background: #000;
  background: linear-gradient(170deg, #fb6340 23%, #712497 73%, #000 114%);
  color: #fff;
}
.section-shaped .shape-halloween--bubble :nth-child(1),
.section-shaped .shape-halloween--bubble :nth-child(2),
.section-shaped .shape-halloween--bubble :nth-child(3),
.section-shaped .shape-halloween--bubble :nth-child(4),
.section-shaped .shape-halloween--bubble :nth-child(5) {
  background: #712497;
  background: linear-gradient(210deg, #fb6340 33%, #712497 73%, #5863f8 94%);
}
.section-shaped .shape-love {
  background: #ef2d56;
  background: linear-gradient(0deg, #ef2d56 0%, #ef2d56 100%);
  color: #fff;
}
.section-shaped .shape-love--bubble :nth-child(1),
.section-shaped .shape-love--bubble :nth-child(2),
.section-shaped .shape-love--bubble :nth-child(3),
.section-shaped .shape-love--bubble :nth-child(4),
.section-shaped .shape-love--bubble :nth-child(5) {
  background: #ef2d56;
  background: linear-gradient(45deg, #ef2d56 20%, #ef2d56 70%, #ef2d56 114%);
}
.section-shaped .shape-black--bubble :nth-child(1),
.section-shaped .shape-black--bubble :nth-child(2),
.section-shaped .shape-black--bubble :nth-child(3),
.section-shaped .shape-black--bubble :nth-child(4),
.section-shaped .shape-black--bubble :nth-child(5), .section-shaped .shape-black {
  background: #000;
  background: linear-gradient(210deg, #222222 23%, #000 113%, #ced4da 94%);
  color: #fff;
}
.bg-overlay {
  height: 100%;
  position: relative;
}
.bg-overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
@media screen and (max-width: 780px) {
  .bg-overlay::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.overlay-black::before {
  background-color: rgba(0, 0, 0, 0.7);
}
@media screen and (max-width: 780px) {
  .overlay-black::before {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.overlay-white::before {
  background-color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 780px) {
  .overlay-white::before {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.device-ill {
  pointer-events: none;
  position: absolute;
  display: flex;
  width: 1287px;
  left: 50%;
  margin-left: -644px;
  transform: scale(0.5) rotate(-12deg) translateX(50px);
  transform-origin: 50% 20%;
  will-change: transform;
}
.device-ill div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0.875rem;
  background: #fff;
  box-shadow: inset 0 4px 7px 1px #fff, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.05);
}
.device-ill .tablet-landscape {
  width: 512px;
  height: 352px;
  margin: 115px 50px 0;
}

@media (min-width: 670px) {
  .device-ill {
    flex-wrap: wrap;
    width: 512px;
    margin-left: -50px;
    top: 215px;
    transform: rotate(-12deg);
    transform-origin: 100% 0;
  }
  .device-ill [class^=tablet] {
    margin: 0;
  }
  .device-ill .tablet-landscape {
    width: 512px;
    height: 352px;
  }
}
@media (min-width: 880px) {
  .device-ill {
    width: 829px;
    margin-left: -10px;
    top: 20px;
  }
  .device-ill .tablet-landscape {
    align-self: flex-end;
    margin-right: 50px;
  }
  .device-ill .phone-big {
    display: flex;
    width: 267px;
    height: 553px;
  }
}
.section-profile-cover {
  height: 580px;
  background-size: cover;
  background-position: center center;
}

.section-components > .form-control + .form-control {
  margin-top: 0.5rem;
}
.section-components > .nav + .nav,
.section-components > .alert + .alert,
.section-components > .navbar + .navbar,
.section-components > .progress + .progress,
.section-components > .progress + .btn,
.section-components .badge,
.section-components .btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.section-components .btn-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.section-components .btn-group .btn {
  margin: 0;
}
.section-components .alert {
  margin: 0;
}
.section-components .alert + .alert {
  margin-top: 1.25rem;
}
.section-components .badge {
  margin-right: 0.5rem;
}
.section-components .modal-footer .btn {
  margin: 0;
}

.floating-cards {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.floating-cards > div {
  position: absolute;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}

.floating-cards .shine {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.font-prompt h1,
.font-prompt h2,
.font-prompt h3,
.font-prompt h4,
.font-prompt h5,
.font-prompt h6 {
  font-family: "Prompt", sans-serif;
}
.font-prompt p {
  font-family: "Nanum Gothic", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.font-raleway h1,
.font-raleway h2,
.font-raleway h3,
.font-raleway h4,
.font-raleway h5,
.font-raleway h6 {
  font-family: "Raleway", sans-serif;
}
.font-raleway p {
  font-family: "Jost", sans-serif;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1rem !important;
}
p a {
  color: #5863f8;
  text-decoration: underline;
}

#our-work ul,
#footer ul {
  margin-left: 0;
  margin-bottom: 16px;
  list-style-type: none;
}

ul {
  margin-left: 24px;
  margin-bottom: 16px;
  list-style-type: disc;
}

.list-article li:before {
  content: "\f006";
  font-family: FontAwesome;
  color: #5863f8;
  margin-right: 16px;
}

.description {
  font-size: 0.875rem;
}

h1,
h2,
h3,
h4 {
  font-family: "Jost", sans-serif;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: inherit;
  font-weight: 400;
}

h1,
.h1 {
  font-size: 3rem;
}
@media screen and (min-width: 320px) {
  h1,
.h1 {
    font-size: calc(2rem + 6 * (100vw - 320px) / 680);
  }
}
@media screen and (min-width: 1000px) {
  h1,
.h1 {
    font-size: 2.75rem;
  }
}

h2,
.h2 {
  font-size: 2.75rem;
}
@media screen and (min-width: 320px) {
  h2,
.h2 {
    font-size: 2.5rem;
  }
}

h3,
.h3 {
  font-size: 2.5rem;
}
@media screen and (min-width: 320px) {
  h3,
.h3 {
    font-size: calc(1.5rem + 6 * (100vw - 320px) / 680);
  }
}

h4,
.h4 {
  font-size: 2.3125rem;
}
@media screen and (min-width: 320px) {
  h4,
.h4 {
    font-size: calc(1.5rem + 6 * (100vw - 320px) / 680);
  }
}

article h4:not(:first-child),
article h5:not(:first-child) {
  margin-top: 3rem;
}
article h4,
article h5 {
  margin-bottom: 1.5rem;
}
article figure {
  margin: 3rem 0;
}
article h5 + figure {
  margin-top: 0;
}

.text-shadow {
  text-shadow: 0px 3px 6px #000;
}

.line-height-08 {
  line-height: 0.8;
}

.bold-900 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.filter-orange {
  filter: invert(33%) sepia(87%) saturate(1768%) hue-rotate(3deg) brightness(109%) contrast(102%);
}

.filter-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(101%) contrast(102%);
}

.filter-yellow {
  filter: invert(62%) sepia(76%) saturate(731%) hue-rotate(6deg) brightness(112%) contrast(105%);
}

.filter-pink {
  filter: invert(39%) sepia(100%) saturate(6703%) hue-rotate(313deg) brightness(100%) contrast(122%);
}

.animate-shake {
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  20% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}